<template>
  <el-container>
    <el-aside ref="aside" :width="showAside ? '232px' : '16px'" style="margin-right: 16px;">
      <el-button
        :icon="showAside ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
        class="treeToggle"
        @click="handleCollapse"></el-button>
      <transition name="el-fade-in-linear">
        <vm-tree
          v-show="showAside"
          class="treeSide"
          type="useUnit/false"
          @node-click="onNodeClick"
          @area-click="onAreaClick"
          @node-clear="onNodeClear">
        </vm-tree>
      </transition>
    </el-aside>
    <el-main style="padding:0;width:100vh">
      <vm-table ref="vmTable" :loading="loading" :filter.sync="search" url="users/new">
        <template slot="toolbar">
          <el-button v-if="editAuth" class="addBtn" @click="handleAdd">
            {{ $t('common.add') }}
          </el-button>
          <el-button v-if="editAuth" @click="$refs.userLocked.open()">{{$t('user.accountUnlock')}}</el-button>
        </template>
        <template slot="adSearch">
          <vm-search :label="$t('common.filter')">
            <el-input v-model.trim="search.keyword" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
          <vm-search :label="$t('user.type')">
            <el-select v-model="search.userType" :placeholder="$t('common.pleaseSelect')" clearable>
              <el-option
                v-for="item in userTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
        </template>
        <el-table-column prop="username" :label="$t('user.username')" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('user.type')" width="120">
          <template v-slot="scope">
            <el-tag v-if="scope.row.type === 0 || scope.row.type === 1 || scope.row.type === 3">
              {{ $t("user.types." + scope.row.type) }}
            </el-tag>
            <el-tag v-else type="info">{{ $t("user.types." + scope.row.type) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('user.name')" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" :label="$t('user.phone')" width="110" align="center"></el-table-column>
        <el-table-column prop="orgName" :label="$t('user.company')" show-overflow-tooltip></el-table-column>
        <el-table-column v-if="showOrgId" prop="orgName" :label="$t('elevator.belongCompany')" show-overflow-tooltip></el-table-column>
        <el-table-column v-if="showOrgId" prop="tenantName" :label="$t('user.tenant')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="loginTime" :label="$t('user.lastLoginTime')" width="140" align="center"></el-table-column>
        <el-table-column prop="createTime" :label="$t('msgPushRecord.createTime')" width="140" align="center"></el-table-column>
        <el-table-column prop="creator" :label="$t('gatewayVersion.creator')" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column
          v-if="editAuth"
          :label="$t('common.operation')"
          width="150"
          align="center"
          fixed="right"
          class-name="vm-table_operate">
          <template v-slot="scope">
            <el-button type="text" :disabled="isEditDisabled(scope.row)" @click="handleEdit(scope.row)">
              {{ $t("common.edit") }}
            </el-button>
            <el-button type="text" class="warningBtn" :disabled="isDisabled(scope.row)" @click="resetPassword(scope.row)">
              {{ $t("user.resetPassword") }}
            </el-button>
            <el-button
              type="text"
              class="operateDelBtn"
              :disabled="isDisabled(scope.row)"
              @click="handleDelete(scope.row)">
              {{ $t("common.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </vm-table>
    </el-main>
    <edit-dialog ref="editPage" @save-success="getList($event ? 1 : -1)"></edit-dialog>
    <user-locked ref="userLocked"></user-locked>
  </el-container>
</template>
<script>
  import EditDialog from "./UserEdit.vue";
  import auth from "../../util/auth";
  import vmTree from "@/components/VmTree";
  import { typeToArray } from "@/util/constant";
  import UserLocked from "./UserLocked.vue";

  export default {
    components: { EditDialog, vmTree, UserLocked },
    data() {
      return {
        editAuth: this.$auth(2),
        showOrgId: auth.getUsername() === "superAdmin" || auth.getTenantAdmin() === "true",
        loading: false,
        search: {},
        showAside: false,
        userTypeOptions: typeToArray(this.$t("user.types")),
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = true;
        this.$refs.vmTable.getList(pageNum);
        this.loading = false;
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      handleDelete(row) {
        this.$confirm(this.$t("user.username") + " " + row.username + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("users", row.id).then(() => {
            this.getList(1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
      resetPassword(row) {
        this.$confirm(this.$t("user.reset") + " " + this.$t("user.username") + " " + row.username + " " + this.$t("user.is") + this.$t("user.tip.tips") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$axios.post("users/" + row.id + "/password").then(res => {
            this.$message.success(this.$t("user.tip.successful"));
          }).catch(() => {
            this.$message.error(this.$t("user.tip.failed"));
          });
        });
      },
      isDisabled(row) {
        return row.username === "admin" || row.username === "superAdmin";
      },
      isEditDisabled(row) {
        return row.username === "admin" || row.username === "superAdmin" || ((row.type === 2 || row.type === 4) && auth.getUserType() === 0);
      },
      handleCollapse() {
        this.showAside = !this.showAside;
        if (this.showAside) {
          this.$refs.aside.$el.setAttribute("style", "margin-right: 20px");
        } else {
          this.$refs.aside.$el.setAttribute("style", "margin-right: 0px");
        }
      },
      onNodeClick(data) {
        this.search.companyId = data.id;
        this.getList(1);
      },
      onAreaClick(data) {
        this.search.companyId = data.id;
        this.getList(1);
      },
      onNodeClear() {
        this.search.companyId = "";
        this.getList(1);
      },
    },
  };
</script>
<!-- <style lang="scss" scoped>
.vm-main .vm-main-page .vm-content .vm-page {
  padding: 17px 22px 17px 0;
}

::v-deep .el-tree {
  overflow-x: auto;
}
</style> -->
